import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Container, Typography, Box } from "@material-ui/core";
import PrintLogTable from "./PrintLogTable";
import { getPrintLogs, selectPrintLogs } from "../Receipt/printSlice";

const PrintLog = () => {
  const dispatch = useDispatch();
  const printlogs = useSelector(selectPrintLogs);
  const [pageSize, setPageSize]  = useState(50)

  useEffect(() => {
    dispatch(getPrintLogs(1, pageSize));
  }, []);

  const handlePageChange = (newPage: number) => {
    dispatch(getPrintLogs(newPage, pageSize));
  };

  const handlePageSizeChange = (newPageSize: number) => {
    setPageSize(newPageSize);
    dispatch(getPrintLogs(1, newPageSize));
  };

  return (
    <Container>
      <Box
        display="flex"
        flexDirection="column"
        style={{ marginBottom: "10px" }}
      >
        <Box display="flex" flexDirection="row" m={2}>
          <Box flexGrow="0">
            <Typography variant="h4">Tulostuslogi</Typography>
          </Box>
        </Box>
        <PrintLogTable items={printlogs.items} pagination={printlogs.pagination} onPageChange={handlePageChange} pageSize={pageSize} onPageSizeChange={handlePageSizeChange} />
      </Box>
    </Container>
  );
};
export default PrintLog;
