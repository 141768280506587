import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, RootState } from "../../app/store";
import { ReceiptState } from "../../slices/receiptSlice";
import API from "../../util/api";

interface ReceiptsIds {
  id: string;
}
export interface PrintLogState {
  id: number;
  printedDate: string;
  user: string;
  receiptsCount: number;
  receiptsIds: ReceiptsIds[];
  companyName: string;
}
interface PrintState {
  receipts: ReceiptState[];
  printlogs: {
    items: PrintLogState[];
    pagination: PaginationInfo;
  };
}

interface PaginationInfo {
  current_page: number;
  first_page_url: string;
  from: number;
  last_page: number;
  last_page_url: string;
  next_page_url: string;
  path: string;
  per_page: number;
  prev_page_url: string;
  to: number;
  total: number;
}


const initialState: PrintState = {
  receipts: [],
  printlogs: {
    items: [],
    pagination: {
      current_page: 1,
      first_page_url: "",
      from: 0,
      last_page: 1,
      last_page_url: "",
      next_page_url: "",
      path: "",
      per_page: 0,
      prev_page_url: "",
      to: 0,
      total: 0,
    },
  },
};

export const printSlice = createSlice({
  name: "print",
  initialState,
  reducers: {
    setPrintReceipts: (state, action: PayloadAction<ReceiptState[]>) => {
      state.receipts = action.payload;
    },
    setPrintLogs: (state, action: PayloadAction<{ data: PrintLogState[] & Record<string, any> }>) => {
      const { data, ...pagination } = action.payload;
      const printlogs = data.map((printlog: any) => {
        return {
          id: printlog.id,
          printedDate: printlog.printed_date,
          user: printlog.user.name,
          receiptsCount: printlog.receipts_count,
          receiptsIds: printlog.receipts,
          companyName: printlog.company_name,
        };
      });
      state.printlogs = {
        items: printlogs,
        pagination: pagination as PaginationInfo,
      };
    },
  },
});

export const getPrintLogs = (page: number, pageSize: number): AppThunk => (dispatch) => {
  return API.getPrintLogs(page, pageSize).then((response: any) => {
    console.log("getPrintLogs response data: map as items and so on", response.data.data)
    dispatch(setPrintLogs(response.data.data))

  });
};

export const { setPrintReceipts, setPrintLogs } = printSlice.actions;

export const selectPrintReceipts = (state: RootState) => state.print.receipts;
export const selectPrintLogs = (state: RootState) => state.print.printlogs;
//export const selectPrintLog = (state: RootState)

export default printSlice.reducer;
